import './home.scss';

console.log('home.js');

setTimeout(() => {
    var swiper = jQuery(".ff-widget-carousel .swiper" ).data("swiper");
    swiper.params.effect = "fade"; 
    swiper.params.fadeEffect.crossFade = true;
    swiper.destroy(false)
    new Swiper(".ff-widget-carousel .swiper", swiper.params);
}, 1000);
